::-webkit-scrollbar {
    display: none;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#table-input {
    margin-left: 10px;
    text-align: center;
    border: none;
    border-bottom: 1px solid grey;
    width: 50px;
    outline: none;
}

input#table-input::-webkit-outer-spin-button,
input#table-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input#table-input {
    -moz-appearance: textfield;
}
